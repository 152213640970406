import axios from "axios";
import {
  GoogleOauthRequestPayload,
  LoginReturnType,
} from "src/components/Auth/LogIn/api/interfaces";
import API from "./index";
import { GuestLoginPayload, GuestLoginReturnType } from "src/pages/api/interfaces";
import { LogInPayload } from "src/components/Auth/LogIn/api/schema";
import { SignUpPayload } from "src/components/Auth/SignUp/api/schema";
import { constants } from "src/constant";
import { SignupReturnType } from "src/components/Auth/SignUp/api/interfaces";
import {
  ResetPasswordRequestPayload,
  ResetPasswordSuccessPayload,
  SendOTPRequestPayload,
  SendOTPSuccessPayload,
  VerifyOTPRequestPayload,
  VerifyOTPSuccessPayload,
} from "src/components/Auth/SignUp/api/types";
import {
  CheckEmailRegistrationStatusRequestPayload,
  CheckEmailRegistrationStatusResponsePayload,
  RequestTokenOnAuthSuccessRequestPayload,
  RequestTokenOnAuthSuccessResponsePayload,
} from "src/components/Auth/api/interfaces";
import platform from "platform";
import { googleOAuthConfig } from "src/auth.config";
import { getDeviceInfo } from "src/utils/getDeviceInfo";

export interface CurrentUser {
  id: string;
  name: string | null;
  pictureUrl: string | null;
  phoneNumber: string | null;
  email: string | null;
  role: string | null;
  status: string | null;
  address: Array<{
    id: number;
    propertyType: string;
    propertySubType: string;
    postalCode: string;
    detail: string;
    tag: string;
    isDefault: boolean;
    district: string;
    streetName: string;
    blockNumber: string;
    unitNumber: string;
    additionalRemark: string;
  }>;
}

export const getCurrentUser = async (): Promise<CurrentUser | null> => {
  const accessTokenLocal = localStorage.getItem(constants.ACCESS_TOKEN_STORAGE_KEY);
  const refreshTokenLocal = localStorage.getItem(constants.REFRESH_TOKEN_STORAGE_KEY);

  const accessTokenSession = sessionStorage.getItem(constants.ACCESS_TOKEN_STORAGE_KEY);
  const refreshTokenSession = sessionStorage.getItem(constants.REFRESH_TOKEN_STORAGE_KEY);

  const hasTokens =
    (!!accessTokenLocal && !!refreshTokenLocal) || (!!accessTokenSession && !!refreshTokenSession);
  if (!hasTokens) {
    return null;
  }
  const response = await API.get<{ customer: CurrentUser }>("/customer/profile");

  return response.data.customer;
};

export const signUp = async ({
  email,
  password,
  phoneNumber,
  role,
  deviceInfo,
}: SignUpPayload): Promise<SignupReturnType> => {
  const payload = new FormData();

  if (email) {
    payload.append("email", email);
  }
  if (phoneNumber) {
    payload.append("phoneNumber", phoneNumber);
  }

  payload.append("password", password);
  payload.append("role", role);
  payload.append("deviceInfo", JSON.stringify(deviceInfo));

  const response = await API.post("/customer/register", {
    email,
    password,
    phoneNumber,
    role,
    deviceInfo: JSON.stringify(deviceInfo),
  });
  const accessToken = response.headers["x-access-token"].split(" ")[1] ?? null;
  const refreshToken = response.headers["x-refresh-token"].split(" ")[1] ?? null;

  return {
    token: {
      accessToken,
      refreshToken,
    },
  };
};

export const logIn = async ({
  email,
  password,
  phoneNumber,
  role,
  deviceInfo,
}: LogInPayload): Promise<LoginReturnType> => {
  const response = await API.post("/customer/login", {
    email,
    password,
    phoneNumber,
    role,
    deviceInfo,
  });
  const accessToken = response.headers["x-access-token"].split(" ")[1] ?? null;
  const refreshToken = response.headers["x-refresh-token"].split(" ")[1] ?? null;

  return {
    token: {
      accessToken,
      refreshToken,
    },
  };
};

export const logout = async (): Promise<void> => {
  await API.post("/customer/logout");

  localStorage.removeItem(constants.ACCESS_TOKEN_STORAGE_KEY);
  localStorage.removeItem(constants.REFRESH_TOKEN_STORAGE_KEY);
};

export const sendOTP = async ({
  phoneNumber,
  needUserCheck,
  role,
  forgotPassword,
}: SendOTPRequestPayload) => {
  const response = await API.post<SendOTPSuccessPayload>("/customer/send-otp", {
    phoneNumber: phoneNumber,
    needUserCheck: needUserCheck,
    role: role ?? "CUSTOMER",
    forgotPassword: forgotPassword,
  });
  return response.data;
};

export const guestLogin = async ({
  deviceType,
  deviceModel,
  os,
  browser,
  phoneNumber,
}: GuestLoginPayload): Promise<GuestLoginReturnType> => {
  const response = await API.post("/customer/guest-login", {
    phoneNumber: phoneNumber,
    deviceInfo: JSON.stringify({
      deviceType,
      deviceModel,
      os,
      browser,
    }),
  });

  const accessToken = response.headers["x-access-token"]?.split(" ")[1] ?? null;
  const refreshToken = response.headers["x-refresh-token"]?.split(" ")[1] ?? null;

  return {
    token: {
      accessToken,
      refreshToken,
    },
  };
};

export const verifyOTP = async ({ pin, phoneNumber, role }: VerifyOTPRequestPayload) => {
  const response = await API.post<VerifyOTPSuccessPayload>("/customer/verify-otp", {
    otp: pin,
    phoneNumber,
    role: role ?? "CUSTOMER",
  });
  return response.data;
};

export const resetPassword = async ({
  phoneNumber,
  newPassword,
  role,
}: ResetPasswordRequestPayload) => {
  const response = await API.post<ResetPasswordSuccessPayload>("/customer/forgot-password", {
    phoneNumber,
    newPassword,
    deviceInfo: JSON.stringify({
      deviceType: platform.product ?? "",
      deviceModel: platform.version ?? "",
      os: (platform.os?.family as string) ?? "",
      browser: platform.name ?? "",
    }),
    role: role,
  });
  return response.data;
};

export const checkRegistrationStatus = async ({
  email,
  password,
}: CheckEmailRegistrationStatusRequestPayload) => {
  const payload = {
    email,
    password,
    deviceInfo: getDeviceInfo(),
  };

  try {
    const response = await API.post<CheckEmailRegistrationStatusResponsePayload>(
      "/customer/email-register",
      payload,
    );

    const accessToken = response.headers["x-access-token"]?.split(" ")[1] ?? null;
    const refreshToken = response.headers["x-refresh-token"]?.split(" ")[1] ?? null;

    return { ...response.data, token: { accessToken, refreshToken } };
  } catch (error) {
    throw error;
  }
};

export const googleOAuthSuccessCallback = async ({ accessToken }: GoogleOauthRequestPayload) => {
  const params = new URLSearchParams();
  params.append("access_token", accessToken);

  try {
    const response = await axios.get(`${googleOAuthConfig.url}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const requestTokenOnOAuthSuccess = async ({
  providerId,
  provider,
  email,
  deviceInfo,
}: RequestTokenOnAuthSuccessRequestPayload) => {
  try {
    const response = await API.post<RequestTokenOnAuthSuccessResponsePayload>(
      `/auth/social-login`,
      {
        providerId,
        provider,
        email,
        deviceInfo,
      },
    );

    const accessToken = response?.headers["x-access-token"]?.split(" ")[1] ?? null;
    const refreshToken = response?.headers["x-refresh-token"]?.split(" ")[1] ?? null;

    return {
      success: response.data.success,
      data: response.data.data,
      token: {
        accessToken,
        refreshToken,
      },
    };
  } catch (error) {
    throw error;
  }
};
